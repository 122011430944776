import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppType } from "next/app";
import { Montserrat } from "next/font/google";
import CookieBanner from "@components/CookieBanner";
import ScrollButton from "@components/ScrollButton";
import "~/styles/globals.scss";
import "react-alice-carousel/lib/alice-carousel.css";
import "~/styles/alice-carousel.scss";
import { FontSizeProvider } from "~/components/FontSizeContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ErrorBoundary } from "@sentry/nextjs";
import { ErrorSection } from "~/pages/_error";
import Header from "@components/layout/Header";
import Footer from "@components/layout/Footer";
import NextNProgress from "nextjs-progressbar";
import useCookiePolicy from "@hooks/useCookiePolicy";
import { env } from "~/env.mjs";
import Script from "next/script";
const queryClient = new QueryClient();

const montserrat = Montserrat({
  variable: "--font-montserrat",
  weight: ["300", "400", "500", "600", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
});

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const { cookiePolicy } = useCookiePolicy();
  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=G-PQYJ1GX6TY" />
      {cookiePolicy && env.NEXT_PUBLIC_GTM_ENABLED === "true" && (
        <Script id="google-analytics">
          {`
          window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'G-PQYJ1GX6TY');
        `}
        </Script>
      )}
      <FontSizeProvider>
        <QueryClientProvider client={queryClient}>
          <SessionProvider session={session}>
            <div
              className={`app-container text-black ${montserrat.variable} ${montserrat.className}`}
            >
              <ErrorBoundary
                fallback={(errorData) => <ErrorSection {...errorData} />}
              >
                <Header />
                <NextNProgress color="#f49332" />
                <Component {...pageProps} />
                <Footer />
              </ErrorBoundary>
              <ScrollButton />
              <CookieBanner />
            </div>
          </SessionProvider>
        </QueryClientProvider>
      </FontSizeProvider>
    </>
  );
};

export default MyApp;
