import { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import search from "@public/icon/ico-search.svg";
import buddismoSocieta from "@public/bs-buddismo-e-societa.svg";
import bs from "@public/bs.svg";
import down from "@public/icon/ico-select-black.svg";
import { useSession } from "next-auth/react";
import { LOGOUT_URL } from "~/env.mjs";
import useLoginURL from "@hooks/useLoginURL";
import { formatISODateString, formatISOTimeString } from "~/utils/dateFormat";
import ibisg from "@public/icon/ibisg.svg";

const Header = () => {
  const session = useSession();
  const loginURL = useLoginURL();
  const isAuthenticated = session?.status === "authenticated";
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [MenuNavHeader, setMenuNavHeader] = useState<boolean>(false);

  useEffect(() => {
    const dateDay = new Date();
    setDate(formatISODateString(dateDay.toString()));
    setTime(formatISOTimeString(dateDay.toString()));
  }, []);

  return (
    <header className="w-full bg-[#ffffff] md:sticky md:top-[-170px] md:z-[100] lg:top-[-260px] print:top-0 print:pt-4">
      <div className="bg-[#E8E9E9] px-4 font-medium print:hidden">
        <nav className="relative mx-auto flex max-w-box items-center justify-between overflow-hidden py-1.5">
          <div className="flex flex-shrink-0 items-center gap-6 md:mr-6">
            <a
              href="https://www.sgi-italia.org/"
              target="_blank"
              rel="noreferrer noopener"
              className="inline-block hover:opacity-90"
            >
              <Image
                width={35}
                height={35}
                src={ibisg}
                alt="Istituto Buddista Italiano Soka Gakkai"
                className="py-1"
              />
            </a>
            <div className="mx-1 h-auto overflow-hidden">
              <p className="track flex animate-[marquee_50s_linear_infinite] items-center gap-1 whitespace-nowrap text-sm  text-black ">
                <span className="">
                  BUDDISMO E SOCIETÀ | per la pace, la cultura e l’educazione ·
                </span>
                <span>
                  BUDDISMO E SOCIETÀ | per la pace, la cultura e l’educazione ·
                </span>
                <span>
                  BUDDISMO E SOCIETÀ | per la pace, la cultura e l’educazione ·
                </span>
                <span>
                  BUDDISMO E SOCIETÀ | per la pace, la cultura e l’educazione ·
                </span>
                <span>
                  BUDDISMO E SOCIETÀ | per la pace, la cultura e l’educazione ·
                </span>
                <span>
                  BUDDISMO E SOCIETÀ | per la pace, la cultura e l’educazione ·
                </span>
              </p>
            </div>
          </div>
          <div className="absolute right-0 flex h-full items-stretch gap-[1px] bg-[#E8E9E9] pl-4">
            <div className="flex-grow">
              {isAuthenticated ? (
                <a
                  target="_blank"
                  href="https://servizi.sgi-italia.org/abbonamenti/index.php/site/statoAbbonamenti"
                  className="mt-0 flex h-full items-center bg-orange px-3.5 py-2.5 text-sm text-white hover:underline hover:opacity-90 lg:mr-4"
                >
                  Il mio abbonamento
                </a>
              ) : (
                <a
                  target="_blank"
                  href="https://servizi.sgi-italia.org/abbonamenti/index.php/site/abbonamenti"
                  className="mt-0 flex h-full items-center bg-orange px-3.5 py-2.5 text-sm text-white hover:underline hover:opacity-90 lg:mr-4"
                >
                  Per abbonarsi
                </a>
              )}
            </div>
            {date != null && time != null && (
              <div className="hidden items-center text-sm lg:flex lg:flex-grow">
                {date + " Ore " + time}
              </div>
            )}
          </div>
        </nav>
      </div>
      <div className="mx-auto max-w-box px-4 pt-10 print:pt-0">
        <nav className="flex items-center justify-between md:py-1.5 ">
          <div className="flex flex-shrink-0 items-center gap-6 sm:mr-6 ">
            <figure className="logo-nr-esteso hidden sm:block sm:max-w-[300px] lg:max-w-full print:max-w-[300px]">
              <Link href="/" className="hover:opacity-90">
                <Image
                  width={600}
                  height={135}
                  src={buddismoSocieta}
                  alt="Buddismo e Società: mensile di approfondimento della filosofia buddista e attualità"
                  className=""
                />
              </Link>
            </figure>
            <figure className="logo-nr inline-block h-auto min-h-[1px] w-[110px] max-[319px]:w-[75px] sm:hidden">
              <Link href="/" className="hover:opacity-90">
                <Image
                  width={110}
                  height={800}
                  src={bs}
                  alt="Buddismo e Società: mensile di approfondimento della filosofia buddista e attualità"
                  className="md:block md:w-full md:max-w-[400px]"
                />
              </Link>
            </figure>
          </div>
          <div className="flex w-auto items-center gap-1 md:gap-2 print:hidden">
            <div>
              {session?.status === "authenticated" && (
                <a
                  href={LOGOUT_URL}
                  className="login ml-[60px] rounded-full bg-lightGray px-8 py-3 text-sm text-white hover:underline hover:opacity-90"
                >
                  Esci
                </a>
              )}

              {session?.status === "loading" && (
                <div className="h-8 w-16 animate-pulse rounded-full bg-slate-200 dark:bg-slate-700"></div>
              )}

              {session?.status === "unauthenticated" && (
                <a
                  href={loginURL}
                  className="login ml-[10px] rounded-full bg-brown px-9 py-3 text-sm text-white hover:underline hover:opacity-90"
                >
                  Accedi
                </a>
              )}
            </div>
            <div className="hidden flex-grow text-sm sm:block lg:ml-10">
              <p className="font-normal text-gray hover:opacity-80">
                <Link
                  className="flex items-center gap-1 md:px-2 md:py-2.5"
                  href="/ricercaAvanzata/"
                  data-type="page"
                  data-id="20697"
                >
                  <span className="hidden lg:flex">Cerca</span>
                  <Image
                    width={25}
                    height={25}
                    src={search}
                    alt="Cerca l'articolo di tuo interesse"
                    className="ml-4 block"
                  />
                </Link>
              </p>
            </div>
          </div>
        </nav>
      </div>
      <div className="ml-auto mr-auto max-w-box px-4 pt-6 lg:pt-12 print:mb-10">
        <div className="top-bar relative z-10 flex items-center justify-between border-b border-solid border-lightGray pt-2 max-md:pb-2">
          <div className="mr-6 flex flex-shrink-0 items-center gap-6 print:hidden">
            <div className="flex w-auto items-center md:hidden">
              <button onClick={() => setMenuNavHeader(!MenuNavHeader)}>
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 5v1.5h14V5H5zm0 7.8h14v-1.5H5v1.5zM5 19h14v-1.5H5V19z"></path>
                </svg>
              </button>
            </div>
            <ul className="m-auto hidden gap-4 font-semibold md:flex">
              <li className="relative flex items-center pb-6">
                <Link
                  href="/numeri-precedenti/"
                  className="text-sm font-normal uppercase tracking-[1px] hover:text-orange"
                >
                  numeri
                </Link>
              </li>
              <li
                className="flex items-center pb-6"
                onClick={() => setMenuNavHeader(!MenuNavHeader)}
                onMouseMove={() => setMenuNavHeader(true)}
                onMouseOut={() => setMenuNavHeader(false)}
              >
                <Link
                  href="#"
                  className="text-sm font-normal uppercase tracking-[1px] hover:text-orange"
                >
                  Sezioni
                </Link>
                <button>
                  <Image
                    width={10}
                    height={10}
                    src={down}
                    alt=""
                    className="ml-1 block"
                  />
                </button>

                <ul
                  className={`absolute left-0 top-full h-auto w-full rounded-ee-3xl rounded-es-3xl border-2 border-[#fff] border-t-[#3b444d] bg-white px-8 pb-14 pt-10 shadow-[60px_60px_90px_rgba(0,0,0,0.2)] lg:px-14 xl:w-2/3   ${
                    MenuNavHeader == true ? "hidden md:block" : "hidden"
                  }`}
                >
                  {" "}
                  <li>
                    <ul className="grid grid-cols-3 gap-5 md:gap-8">
                      <div>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/speciali/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Speciali</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/lo-studio"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Lo studio mensile</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5 ">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/editoriale/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Editoriali</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/principi-fondamentali/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Principi Fondamentali</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/per-i-bambini-e-le-bambine/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Per i bambini e le bambine</span>
                          </Link>
                        </li>
                      </div>
                      <div>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/intervista/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Interviste</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/omaggio-a/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Omaggio a</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/recensioni/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Recensioni</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/primo-piano/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Primo piano</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/rubriche/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Rubriche</span>
                          </Link>
                        </li>
                      </div>
                      <div>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/serie/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Serie</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/proposta-di-pace/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Proposte di Pace</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/scritti-di-daisaku-ikeda/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Scritti di Daisaku Ikeda</span>
                          </Link>
                        </li>
                        <li className="border-b-[1px] border-[#e9e9e9] py-1.5">
                          <Link
                            className="text-sm font-normal uppercase text-gray hover:opacity-80"
                            href="/c/corsi-di-studio/"
                            onClick={() => setMenuNavHeader(!MenuNavHeader)}
                          >
                            <span>Corsi di studio</span>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="relative flex items-center pb-6">
                <Link
                  href="/scopri-bs/"
                  className="text-sm font-normal uppercase tracking-[1px] hover:text-orange"
                >
                  Scopri BS
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`fixed bottom-0 left-0 right-0 top-0 z-50 max-w-none bg-[#ffffff] md:hidden ${
            MenuNavHeader === false ? "hidden" : "flex"
          } overflow-auto`}
        >
          <div className="relative flex w-full bg-[#fff] px-4 py-14">
            <button
              className="absolute right-7 top-7"
              onClick={() => setMenuNavHeader(!MenuNavHeader)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                aria-hidden="true"
                focusable="false"
              >
                <path d="M13 11.8l6.1-6.3-1-1-6.1 6.2-6.1-6.2-1 1 6.1 6.3-6.5 6.7 1 1 6.5-6.6 6.5 6.6 1-1z"></path>
              </svg>
            </button>
            <div className="w-full">
              <ul className="flex flex-col gap-3 p-4 font-semibold">
                <li className="flex border-b border-[#e9e9e9] pb-3">
                  <Link
                    className="font-bold uppercase text-gray hover:opacity-80 md:text-base"
                    href="/ricercaAvanzata/"
                    onClick={() => setMenuNavHeader(!MenuNavHeader)}
                  >
                    <span>Cerca</span>
                  </Link>
                </li>
                <li className="flex border-b border-[#e9e9e9] pb-3">
                  <Link
                    className="font-bold uppercase text-gray hover:opacity-80 md:text-base"
                    href="/numeri-precedenti/"
                    onClick={() => setMenuNavHeader(!MenuNavHeader)}
                  >
                    <span>Numeri</span>
                  </Link>
                </li>
                <li className="flex border-b border-[#e9e9e9] pb-3">
                  <Link
                    className="font-bold uppercase text-gray hover:opacity-80 md:text-base"
                    href="/scopri-bs/"
                    onClick={() => setMenuNavHeader(!MenuNavHeader)}
                  >
                    <span>Scopri BS</span>
                  </Link>
                </li>
                <li className="flex flex-col ">
                  <Link
                    className="border-b border-[#e9e9e9] pb-3 font-bold uppercase text-gray hover:opacity-80 md:text-base"
                    href="#"
                    onClick={() => setMenuNavHeader(!MenuNavHeader)}
                  >
                    <span>Sezioni</span>
                  </Link>
                  <ul className="flex flex-col gap-3 pt-3 font-semibold">
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/speciali/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>speciali</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/lo-studio"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Lo studio mensile</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/editoriale/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Editoriali</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/principi-fondamentali/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Principi Fondamentali</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/per-i-bambini-e-le-bambine/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Per i bambini e le bambine</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/intervista/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Interviste</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/omaggio-a/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Omaggio a</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/recensioni/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Recensioni</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/primo-piano/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Primo piano</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/rubriche/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Rubriche</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/serie/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Serie</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/proposta-di-pace/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Proposte di Pace</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/scritti-di-daisaku-ikeda/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Scritti di Daisaku Ikeda</span>
                      </Link>
                    </li>
                    <li className="flex border-b border-b-lightLightGray pb-3 pl-5">
                      <Link
                        className="font-normal uppercase text-gray hover:opacity-80 md:text-base"
                        href="/c/corsi-di-studio/"
                        onClick={() => setMenuNavHeader(!MenuNavHeader)}
                      >
                        <span>Corsi di studio</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
