import { useEffect, useState } from "react";

const COOKIE_EXPIRATION_MILLISECONDS = 6 * 30 * 24 * 60 * 60 * 1000;

const useCookiePolicy = () => {
  const [cookiePolicy, setCookiePolicy] = useState<boolean | null | undefined>(
    undefined
  );

  useEffect(() => {
    const savedCookiePolicy = localStorage.getItem("cookiePolicy");

    if (!savedCookiePolicy) return setCookiePolicy(null);

    const policies = JSON.parse(savedCookiePolicy);

    if (
      !policies?.updateTime ||
      policies?.updateTime + COOKIE_EXPIRATION_MILLISECONDS <
        new Date().getTime()
    )
      return setCookiePolicy(null);

    setCookiePolicy(policies?.analitics);
  }, []);

  const handleCookiePolicy = (analiticsValue: boolean) => {
    localStorage.setItem(
      "cookiePolicy",
      JSON.stringify({
        analitics: analiticsValue,
        updateTime: new Date().getTime(),
      })
    );
    setCookiePolicy(analiticsValue);
  };

  return { cookiePolicy, handleCookiePolicy };
};

export default useCookiePolicy;
