import { type ArticleReturnType } from "~/lib/db/types";
import {
  REGEX_HTMLTAG,
  REGEX_SOUNDCLOUDPLAYLIST,
  REGEX_AUDIOVIMEO,
  REGEX_SOUNDCLOUDTRACK,
} from "./regex";
import { type ArticlePreviewType, type ISSUES } from "~/types";
import { type BlockData } from "./extractBlocksData";

export type SubCategoryDBReturnType = {
  name: string;
  slug: string;
  taxonomy: ISSUES;
  parent_slug: string;
  parent_name: string;
};

export type PostContentBlocksType = {
  blockName: string;
  attrs: BlockData;
  innerBlocks: PostContentBlocksType[];
  innerHTML: string;
  innerContent: string[];
};

export type PostNote = {
  content: string;
  id: string;
};

export const removeHTMLTags = (htmlString: string) =>
  htmlString?.replace(REGEX_HTMLTAG, "");

export const includesClassicEditor = (
  htmlString: string,
  stringCheck: string
) => {
  if (htmlString?.includes(stringCheck)) return true;
  return false;
};

export const createParagraphs = (postContent: string) => {
  return postContent?.replace(/\n/g, "<br />")?.replace(/\n\r/g, "<p>");
};

export const createHtmlAudioVimeo = (postContent: string) => {
  const vimeoTags = postContent.match(REGEX_AUDIOVIMEO);
  const videoIds = vimeoTags.map((vimeoTag) =>
    vimeoTag.replace("audio_vimeo id=", "").replace(/"/g, "")
  );

  videoIds.forEach(
    (vimeoId) =>
      (postContent = postContent.replace(
        `[audio_vimeo id="${vimeoId}"]`,
        `<div class="vimeo-flex"><iframe src="https://player.vimeo.com/video/${vimeoId}" width="640" height="360" frameborder="0" allowfullscreen="allowfullscreen"></iframe></div>`
      ))
  );

  return postContent;
};

export const createHtmlVideoYoutube = (postContent: string) => {
  const youtubeTags = postContent.match(
    /<div class="wp-block-embed__wrapper">\nhttps:\/\/www\.youtube\.com\/watch\?v=[0-9a-zA-z]{11}.*\n<\/div>/g
  );
  const videoLinks = youtubeTags?.map((vimeoTag) =>
    vimeoTag
      .replace(`<div class="wp-block-embed__wrapper">\n`, "")
      .replace(`\n</div>`, "")
  );
  videoLinks?.forEach((videoLink) => {
    const videoId = videoLink.match(/v=[0-9a-zA-z]{11}/g)[0].replace("v=", "");
    postContent = postContent.replace(
      videoLink,
      `<iframe  width="500" height="281" src="https://www.youtube.com/embed/${videoId}?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""></iframe>`
    );
  });

  return postContent;
};

export const createHtmlSoundcloudPlaylist = (postContent: string) => {
  const playlistTags = postContent.match(REGEX_SOUNDCLOUDPLAYLIST);
  const playlistIds = playlistTags?.map((playlistTag) =>
    playlistTag.replace("sc_playlist src=", "").replace(/"/g, "")
  );

  playlistIds?.forEach(
    (playlistId) =>
      (postContent = postContent.replace(
        `[sc_playlist src="${playlistId}"]`,
        `<iframe style="min-height:450px !important" src="${playlistId}&amp;color=%233e8498&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false&amp;show_teaser=false" width="100%" height="450" frameborder="no" scrolling="no"></iframe>`
      ))
  );
  return postContent;
};

export const createHtmlSoundcloudTrack = (postContent: string) => {
  const trackTags = postContent.match(REGEX_SOUNDCLOUDTRACK);
  const trackIds = trackTags?.map((trackTag) =>
    trackTag.replace("sc_track src=", "").replace(/"/g, "")
  );
  trackIds?.forEach(
    (trackId) =>
      (postContent = postContent.replace(
        `[sc_track src="${trackId}"]`,
        `<div class="soundcloud-player"><iframe src="${trackId}&amp;color=%233e8498&amp;inverse=false&amp;auto_play=false&amp;show_user=false&amp;show_reposts=false&amp;show_teaser=false" width="100%" height="20" frameborder="no" scrolling="no"></iframe><small style="margin-top: -3px;display: block;padding-left:30px;font-family:sans-serif; font-size:11px;">CLICCA SULLA FRECCIA E ASCOLTA L\'AUDIO</small></div>`
      ))
  );
  return postContent;
};

export const createBoxContent = (postContent: string) => {
  let content = postContent;
  const element = content.match(/\[box[ a-z-A-Z="]*]/g);
  element?.map((el) => {
    content = content.replace(
      el,
      `<div class="et-box ${
        el.includes("bio") ? "et-bio" : "et-shadow"
      }"><div class="et-box-content">`
    );
  });
  content = content.replaceAll(/\[\/box]/g, "</div></div>");
  return content;
};

export const creaHtmlPost = (
  postContent: string,
  note: PostNote[],
  slug: string
) => {
  if (!postContent) return "";
  let post_content = postContent;
  post_content = changeNoteInPostContent(postContent, note, slug);
  if (includesClassicEditor(postContent, "audio_vimeo"))
    post_content = createHtmlAudioVimeo(post_content);
  if (includesClassicEditor(postContent, "https://www.youtube.com/watch?v="))
    post_content = createHtmlVideoYoutube(post_content);
  if (includesClassicEditor(postContent, "sc_playlist src="))
    post_content = createHtmlSoundcloudPlaylist(post_content);
  if (includesClassicEditor(postContent, "sc_track src="))
    post_content = createHtmlSoundcloudTrack(post_content);
  if (includesClassicEditor(postContent, "[box"))
    post_content = createBoxContent(post_content);
  if (!includesClassicEditor(postContent, "<!-- wp"))
    post_content = createParagraphs(post_content);
  return post_content;
};

export const doesCategoryPostExist = (category) => {
  if (Boolean(category) && category.length > 0) return true;
  return false;
};

export const createNote = (
  post: ArticleReturnType | ArticlePreviewType
): PostNote[] => {
  if (Boolean(post.meta?.footnotes))
    return JSON.parse(Array(post.meta?.footnotes)[0]);
  if (
    Boolean(post?.post_content) &&
    Boolean(post?.post_content?.match(/\[ref\].*\[\/ref\]/g))
  ) {
    const ref = post.post_content.match(/\[ref\].*\[\/ref\]/g);
    const note = [];
    ref.map((nota, index) => {
      note.push({
        content: nota.replace(/\[\/*ref\]/g, ""),
        id: index + 1,
      });
    });
    return note;
  }
  return null;
};

export const changeNoteInPostContent = (
  post: string,
  note: PostNote[],
  slug: string
) => {
  let postContent = post;
  const ref = postContent.match(/\[ref\].*\[\/ref\]/g);
  if (Boolean(ref)) {
    note?.map((nota) => {
      postContent = postContent.replace(
        `${nota.content}`,
        `<sup data-fn="${nota.id}" class="fn"><a id="${
          nota.id
        }-link" href="${slug.replace(/\/$/, "")}#${nota.id}">${
          nota.id
        }</a></sup>`
      );
    });
    postContent = postContent.replace(/\[\/*ref\]/g, "");
  }
  return postContent;
};

export const createHtmlAudioVimeoBlock = (postContent: string, attrs) => {
  const urlvimeo = attrs?.url?.replace(
    "https://vimeo.com",
    "https://player.vimeo.com/video"
  );
  postContent = postContent.replace(
    attrs?.url,
    `<iframe src="${urlvimeo}" width="640" height="361" frameborder="0" allowfullscreen="allowfullscreen"></iframe>`
  );

  return postContent;
};
