import useCookiePolicy from "@hooks/useCookiePolicy";
import { useEffect, useState } from "react";
import CloseIcon from "@public/icon/ico-close.svg";
import Image from "next/image";

const CookieBanner = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [withStatistics, setWithStatistics] = useState(true);

  const { cookiePolicy, handleCookiePolicy } = useCookiePolicy();
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const onClose = () => {
    setShowCookieBanner(false);
  };

  const handleBannerPolicy = (value: boolean) => {
    handleCookiePolicy(value);
    onClose();
  };

  useEffect(() => {
    if (cookiePolicy === null) {
      setTimeout(() => setShowCookieBanner(true), 1000);
    } else if (cookiePolicy !== undefined) {
      setWithStatistics(cookiePolicy);
    }
  }, [cookiePolicy]);

  if (!showCookieBanner)
    return (
      <div
        className=" fixed bottom-[-28px] right-20 z-10 hidden cursor-pointer rounded-t-xl border border-[#f2f2f2] bg-[#f2f2f2] p-2 pt-4 shadow-md transition-all hover:bottom-0 hover:pt-2  md:block print:!hidden"
        onClick={() => setShowCookieBanner(true)}
      >
        <span className="text-sm">Gestisci consenso</span>
      </div>
    );

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10 w-full rounded-3xl border-[#f2f2f2] bg-[#f2f2f2] p-4 shadow-md md:bottom-4 md:left-auto md:right-4 md:w-auto md:max-w-[550px] md:p-8">
      <div className="mb-4 flex justify-evenly text-center text-lg">
        <div />
        <span className="flex-1">Gestisci Consenso Cookie</span>
        <button onClick={onClose}>
          <Image src={CloseIcon} alt="close icon" width={32} height={32} />
        </button>
      </div>
      <p className="md:text-md mb-6 text-sm">
        Per fornire le migliori esperienze, utilizziamo tecnologie come i cookie
        per memorizzare e/o accedere alle informazioni del dispositivo. Il
        consenso a queste tecnologie ci permetterà di elaborare dati come il
        comportamento di navigazione o ID unici su questo sito. Non acconsentire
        o ritirare il consenso può influire negativamente su alcune
        caratteristiche e funzioni.
      </p>

      {showDetails && (
        <ul className="mb-6 w-full divide-y-2">
          <li className="flex py-4">
            <span className="flex-1">Funzionale</span>{" "}
            <span className="text-primary flex w-32 justify-center">
              Sempre Attivo
            </span>
          </li>
          <li className="flex py-4">
            <span className="flex-1">Statistiche</span>
            <span className="flex w-32 justify-center">
              <input
                type="checkbox"
                value="statistics"
                onChange={(event) => setWithStatistics(event.target.checked)}
                checked={withStatistics}
                className="cursor-pointer"
              />
            </span>
          </li>
        </ul>
      )}

      <div className="mb-4 flex gap-4">
        <button
          type="button"
          className="flex-1 rounded-md border-orange bg-orange text-white"
          onClick={() => handleBannerPolicy(true)}
        >
          Accetta
        </button>
        <button
          type="button"
          className="border--neutral-50 text-222222 flex-1 rounded-md bg-neutral-50"
          onClick={() => handleBannerPolicy(false)}
        >
          Nega
        </button>

        {showDetails ? (
          <button
            type="button"
            className="text-222222 flex-1 rounded-md border-neutral-50 bg-neutral-50"
            onClick={() => handleBannerPolicy(withStatistics)}
          >
            Salva
          </button>
        ) : (
          <button
            type="button"
            className="text-222222 flex-1 rounded-md border-neutral-50 bg-neutral-50"
            onClick={() => setShowDetails(!showDetails)}
          >
            Visualizza le preferenze
          </button>
        )}
      </div>
      <div className="flex justify-center gap-2.5">
        <a
          href="https://privacy.sgi-italia.org/#cookie"
          className="font-w font-semibold text-orange underline hover:opacity-90"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
        <a
          href="https://privacy.sgi-italia.org/"
          className="font-w font-semibold text-orange underline hover:opacity-90"
          target="_blank"
          rel="noreferrer"
        >
          Dichiarazione sulla Privacy
        </a>
      </div>
    </div>
  );
};

export default CookieBanner;
