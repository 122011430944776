import { useRouter } from "next/router";
import { LOGIN_URL, env } from "~/env.mjs";

const useLoginURL = () => {
  const router = useRouter();

  const href = new URL(router.asPath, env.NEXT_PUBLIC_SITE_URL);

  return `${LOGIN_URL}?s=${href.toString()}#accedi`;
};

export default useLoginURL;
