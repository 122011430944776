export const MONTHNAMELIST = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

export const formatISODateString = (isoDate: string) =>
  new Date(isoDate).toLocaleDateString("it-IT", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

export const formatISOTimeString = (isoDate: string) =>
  new Date(isoDate).toLocaleTimeString("it-IT", {
    hour: "numeric",
    minute: "numeric",
  });

  export const currentMonthString = (monthNumber: number) => {
    return (MONTHNAMELIST[monthNumber])
}