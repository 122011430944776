import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";
import dotenv from "dotenv";

if (process.env.ENV_PATH) dotenv.config({ path: process.env.ENV_PATH });

const NEXT_PUBLIC_SPAZIO_ABBONAMENTI = z
  .string()
  .url()
  .default("https://servizi.sgi-italia.org/abbonamenti");

const NEXT_PUBLIC_BS_LOGIN_REDIRECT_LINK = z
  .string()
  .url()
  .default("https://buddismoesocieta.org/guido/log1co.php");

  const NEXT_PUBLIC_BS_LOGOUT_REDIRECT_LINK = z
  .string()
  .url()
  .default("https://buddismoesocieta.org/guido/log1co.php");

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    NEXTAUTH_SECRET: z.string().min(1),
    NEXTAUTH_URL: z.string().url().default("http://localhost:3000"),
    NEXT_PUBLIC_SPAZIO_ABBONAMENTI,
    NEXT_PUBLIC_BS_LOGIN_REDIRECT_LINK,
    NEXT_PUBLIC_BS_LOGOUT_REDIRECT_LINK,
    DATABASE_URL: z.string().url(),
    NODE_ENV: z.enum(["development", "test", "production"]),
    WORDPRESS_BASEPATH: z.string().url(),
    VALIDEMAIL:z.string()
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_SPAZIO_ABBONAMENTI,
    NEXT_PUBLIC_BS_LOGIN_REDIRECT_LINK,
    NEXT_PUBLIC_BS_LOGOUT_REDIRECT_LINK,
    NEXT_PUBLIC_NR:z.string().url().default( process.env === "development"
      ? "http://localhost:3000"
      : "https://ilnuovorinascimento.org"),
    NEXT_PUBLIC_SITE_URL: z
      .string()
      .url()
      .default(
        process.env === "development"
          ? "http://localhost:3000"
          : "https://buddismoesocieta.org"
      ),
    NEXT_PUBLIC_GTM_ENABLED: z.string().default("false"),
    // NEXT_PUBLIC_CLIENTVAR: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NEXT_PUBLIC_BS_LOGIN_REDIRECT_LINK:
      process.env.NEXT_PUBLIC_BS_LOGIN_REDIRECT_LINK,
    NEXT_PUBLIC_BS_LOGOUT_REDIRECT_LINK: process.env.NEXT_PUBLIC_BS_LOGOUT_REDIRECT_LINK,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    NEXT_PUBLIC_SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    NEXT_PUBLIC_SPAZIO_ABBONAMENTI: process.env.NEXT_PUBLIC_SPAZIO_ABBONAMENTI,
    NEXT_PUBLIC_NR: process.env.NEXT_PUBLIC_NR,
    DATABASE_URL: process.env.DATABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    WORDPRESS_BASEPATH: process.env.WORDPRESS_BASEPATH,
    NEXT_PUBLIC_GTM_ENABLED: process.env.NEXT_PUBLIC_GTM_ENABLED,
    VALIDEMAIL: process.env.VALIDEMAIL,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});

export const LOGIN_URL = `${env.NEXT_PUBLIC_SPAZIO_ABBONAMENTI}/index.php/site/login`;
export const LOGOUT_URL = `${env.NEXT_PUBLIC_SPAZIO_ABBONAMENTI}/index.php/site/logout`;
