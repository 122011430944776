import { type FC } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { removeHTMLTags } from "~/utils/postUtils";

type LayoutProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  imageURL?: string;
  imageCaption?: string;
  updatedTime?: string;
  robotsContent?: string;
};

const Layout: FC<LayoutProps> = ({
  children,
  title,
  description,
  imageURL,
  imageCaption,
  updatedTime,
  robotsContent,
}) => {
  const router = useRouter();

  const metaTitle = title
    ? `${removeHTMLTags(title).replace("&amp;", " & ")} - Buddismo e società`
    : "Buddismo e società";

  const metaDescription = description
    ? removeHTMLTags(description)
    : "Buddismo e società. Studia gli approfondimenti sui temi legati alla filosofia buddista e all’attualità con gli scritti di Nichiren Daishonin commentati da Daisaku Ikeda.";

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="twitter:description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />

        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:url" content={router.asPath} />
        <meta property="og:site_name" content="Buddismo e società" />
        <meta name="twitter:card" content="summary_large_image" />

        {process.env.NEXT_PUBLIC_SITE_URL.startsWith("https://dev.") ? (
          <>
            <meta name="robots" content="noindex,nofollow" />
            <meta
              name="google-site-verification"
              content="owiWRFUQND56zKmVEdf6h8VUEWH5kLVjFxNj1rVyIWo"
            />
          </>
        ) : (
          <meta
            name="robots"
            content={robotsContent || "index, follow"}
            data-react-helmet="true"
          ></meta>
        )}

        {imageURL && (
          <>
            <meta property="og:image" content={imageURL} />
            <meta name="twitter:image" content={imageURL} />
          </>
        )}

        {imageCaption && (
          <meta property="og:image:alt" content={imageCaption}></meta>
        )}

        {updatedTime && (
          <meta
            property="og:updated_time"
            content="2023-12-11T14:48:38+01:00"
          />
        )}

        <meta name="twitter:title" content={metaTitle} />
        <link
          rel="icon"
          type="image/png"
          href="/favicon/favicon-96x96.png"
          sizes="96x96"
        />
        <link rel="icon" type="image/svg+xml" href="/favicon/favicon.svg" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <meta name="apple-mobile-web-app-title" content="BS" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
      </Head>
      <main className="mt-12">{children}</main>
    </>
  );
};

export default Layout;
