import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import Image from "next/image";
import iconArrowTop from "@public/icon/ico-arrow-top.svg";
const ScrollButton = () => {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
    setShowArrow(false);
  }, []);

  return (
    <>
      <button
        className={classNames(
          "fixed bottom-10 right-10 z-10 flex h-12 w-12 scale-0 items-center justify-center rounded-full bg-orange px-2.5 pb-2.5 pt-4 opacity-70 shadow-lg hover:opacity-75 print:hidden ",
          { "scale-100": showArrow }
        )}
        onClick={scrollToTop}
      >
        <Image
          src={iconArrowTop}
          width={20}
          height={20}
          alt="arrow top"
          className="m-auto block "
        />
      </button>
    </>
  );
};

export default ScrollButton;
