import { createContext, useEffect, useState } from "react";

export const fontSizes = [
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "30px",
  "36px",
];

export type FontSizeContextType = {
  fontSize: string;
  setFontSize: (fontSize: string) => void;
};

export const FontSizeContext = createContext<FontSizeContextType>({
  fontSize: "16px",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFontSize: () => {},
});

type Props = {
  children?: React.ReactNode;
};

export const FontSizeProvider: React.FC<Props> = ({ children }) => {
  const [fontSize, setFontSize] = useState<string>("16px");
  const [firstAction, setFirstAction] = useState<boolean>(true);

  useEffect(() => {
    const savedFontSize = localStorage.getItem("fontSize");
    if (savedFontSize) {
      setFontSize(savedFontSize as string);
    }
  }, []);

  useEffect(() => {
    if (firstAction) {
      setFontSize(window.innerWidth > 768 ? "22px" : "16px");
      setFirstAction(false);
    }

    if (fontSize) {
      localStorage.setItem("fontSize", fontSize);
    }
  }, [fontSize]);

  return (
    <FontSizeContext.Provider value={{ fontSize, setFontSize }}>
      {children}
    </FontSizeContext.Provider>
  );
};
