import { type FallbackRender } from "@sentry/nextjs/types/client";
import Link from "next/link";
import { type FC } from "react";
import Layout from "~/components/Layout";

export const ErrorSection: FC<{
  error?: Error;
  componentStack?: string;
  eventId?: string;
  resetError?: () => void;
}> = ({ error, componentStack, resetError }) => (
  <section className="mx-auto mt-0 max-w-box bg-white px-4 pb-12">
    <div className="container mx-auto min-h-[60vh] px-4 py-8">
      <h2 className="mb-8 text-4xl font-bold md:text-5xl">
        Oops, si e&apos; verificato un errore improvviso! {error?.toString()}
      </h2>

      <hr className="border-secondary mt-6 border" />
      <div className="py-4">
        {resetError ? (
          <p className="text-xl">
            <button
              className="btn text-primary hover:text-primaryHover"
              onClick={resetError}
            >
              Vogliamo riporvare di nuovo?
            </button>
          </p>
        ) : (
          <Link href="/" className="text-primary hover:text-primaryHover">
            Vogliamo riporvare di nuovo?
          </Link>
        )}

        {componentStack && (
          <details className="mt-4">
            <summary className="text-xl">
              <span className="text-primary hover:text-primaryHover">
                Maggiori informazioni
              </span>
            </summary>
            <pre className="text-sm">{componentStack}</pre>
          </details>
        )}

        <p>
          Se il problema persiste, per favore contattaci all&apos;indirizzo{" "}
          <a
            className="text-primary hover:text-primaryHover"
            href="mailto:webmaster@sgi-italia.org"
          >
            webmaster@sgi-italia.org
          </a>
        </p>
      </div>
    </div>
  </section>
);

const ErrorPage: FallbackRender = () => (
  <Layout title="Errore" robotsContent="noindex, nofollow">
    <main>
      <ErrorSection />
    </main>
  </Layout>
);

export default ErrorPage;
