import Image from "next/image";
import Link from "next/link";
import buddismoSocieta from "@public/bs-buddismo-e-societa-negativo.svg";
import facebook from "@public/facebook.svg";
import instagram from "@public/instagram.svg";
import { useSession } from "next-auth/react";
const currentYear = new Date().getFullYear();

const Footer = () => {
  const session = useSession();
  const isAuthenticated = session?.status === "authenticated";
  return (
    <>
      <footer className="print:hidden">
        {!isAuthenticated && (
          <div className="w-full bg-orange py-20 text-white">
            <div className="mx-auto max-w-box px-4 lg:w-1/2">
              <h4 className="mb-6 text-3xl font-bold sm:mb-10 sm:text-center sm:text-4xl md:text-6xl">
                Per abbonarsi
              </h4>
              <p className="text-xl font-normal sm:text-center">
                Se vuoi continuare a leggerci, abbonati alle riviste
                dell’Istituto Buddista Italiano Soka Gakkai: Buddismo e società,
                mensile cartaceo e digitale, e Il Nuovo Rinascimento, quotidiano
                digitale con una selezione mensile cartacea.
              </p>
              <div className="pb-6 pt-12 sm:text-center">
                <Link
                  target="_blank"
                  className="bg-[#009BA2] px-20 py-4 uppercase text-white"
                  href={
                    "https://servizi.sgi-italia.org/abbonamenti/index.php/site/abbonamenti"
                  }
                >
                  Vai
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="w-full bg-gray pb-20 pt-12">
          <div className="mx-auto grid max-w-box grid-cols-2 gap-x-12 gap-y-6 px-4 pt-6 text-sm leading-loose text-white md:grid-cols-3 lg:grid-cols-4 lg:pt-12">
            <div className="col-span-2 md:col-span-3 lg:col-span-4 xl:col-span-1">
              <div>
                <Link
                  href={"https://buddismoesocieta.org"}
                  className="transition-all hover:opacity-75"
                >
                  <Image
                    width={310}
                    height={67}
                    src={buddismoSocieta}
                    alt="Buddismo e Società rivista ufficiale della Soka Gakkai"
                  />
                </Link>
              </div>
              <div className="mt-8 flex gap-4">
                <Link
                  href={"https://www.facebook.com/buddismoesocieta"}
                  className="flex transition-all hover:opacity-75"
                  target="_blank"
                >
                  <Image
                    width={12}
                    height={24}
                    src={facebook}
                    alt="Profilo ufficiale Facebook di Buddismo e Società"
                  />
                </Link>
                <Link
                  href={"https://www.instagram.com/buddismo_e_societa/"}
                  className="flex transition-all hover:opacity-75"
                  target="_blank"
                >
                  <Image
                    width={20}
                    height={20}
                    src={instagram}
                    alt="Profilo ufficiale Instagram di Buddismo e Società"
                  />
                </Link>
              </div>
            </div>
            <div className="col-span-2 flex flex-col border-lightGray pt-4 text-left max-md:border-b max-md:pb-8  md:col-span-1 lg:col-span-2 xl:col-span-1 xl:items-center">
              <div>
                <p className="mb-3 text-sm leading-relaxed md:text-left">
                  Iscritto al n. 3506 dei registri della stampa del tribunale di
                  Firenze l’8 ottobre 1986
                  <br />
                  Redazione: Via Tiburtina 1321 00131 Roma
                </p>
                <p className="mb-3">
                  <Link
                    href="/redazione"
                    className="uppercase tracking-widest text-lightGray transition-all hover:text-orange"
                  >
                    Chi siamo
                  </Link>
                </p>
                <p className="">
                  <Link
                    className="tracking-widest text-lightGray"
                    href={"mailto:buddismo-e-societa@sgi-italia.org"}
                  >
                    buddismo-e-societa@sgi-italia.org
                  </Link>
                </p>
              </div>
            </div>
            <div className="flex flex-col pt-4 text-left max-[460px]:col-span-2 xl:items-center">
              <div>
                <ul>
                  <li>
                    <a
                      href="https://www.sgi-italia.org/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      Istituto Buddista Soka Gakkai
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://senzatomica.it/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      senzatomica
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://cambioiocambiailmondo.it/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      Cambio io / Cambia il mondo
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ottopermille.sokagakkai.it//"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      otto per mille
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col pt-4 text-left max-[460px]:col-span-2 xl:items-center">
              <div>
                <ul>
                  <li>
                    <a
                      href="https://ilnuovorinascimento.org/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      il nuovo rinascimento
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ilvolocontinuo.it/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      il volo continuo
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://biblioteca.sgi-italia.org/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      la biblioteca di nichiren
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://esperiashop.it/"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-medium uppercase transition-all hover:text-orange"
                    >
                      esperia
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-full bg-lightBlack px-4 py-4 text-sm sm:text-center">
          <p className="text-[#5d5c5d]">
            <strong>© Copyright {currentYear}</strong> Istituto Buddista
            Italiano Soka Gakkai. Tutti i diritti riservati | P.IVA: 04935120487
            | Sede Legale: Firenze •{" "}
            <a
              className=" text-[#898787] hover:underline"
              rel="noreferrer noopener"
              href="https://privacy.sgi-italia.org/"
              target="_blank"
            >
              Privacy
            </a>
          </p>
        </div>
      </footer>
      <footer className="hidden px-4 print:block">
        <hr />
        buddismoesocieta.org
      </footer>
    </>
  );
};

export default Footer;
